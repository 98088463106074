import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Install/PoE/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera-poe-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera-poe-models",
        "aria-label": "add a new p2p camera poe models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera (`}<em parentName="h3">{`PoE Models`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7a82cf10f871f5528718143756fa3a7/81315/Android_Wizard_Installation_PoE_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeklEQVQ4y42Ty27TQBSGvaOKWLCoBHWT4NSQNHY8lzPjGY/HtyQWaUJqt24TdUO75QHYsUFI3fECsEDiIXgT3qJLNiyRnZZLJUQ+jTRnZvTPf3TOjNFut13XNU1zZ2en1WDbdqfTNs39/Y7V7/cHg8Hu7m6r1XrY0Ov1up2OaZpPD54Zh4eHtm1nWUYptSzLtu1uTad70N+jR4PBwLZtrfV0Og3DME1Txnmn3e5avSe8MBzHcV2XEOJ5nnuPEXJd13EcQoiU0vd90YAxHg4d10MGbSAN9D4EgAKAECLLsqQhTVOtNWMMgBrkD3DDryVCKIqi1Wq1Xq/Pz89Xq9XFxUVVVWVZXl1dTadT486ysQD4bdrcBQBFUSwWi7IsT09Pi4blclkUhRDCIBgTNIpClaVxEus4jrMsi5OE1lokpDw+Pi4bzs7Oqqo6OTmpqmq5XEZRZFAuaTKHZA7xDJK5aAoDlCAeIiYZUN/3GWOc8zzPZ7OZlBIANjsG86WezPXkKEzzZJzHcRIEAcYIM4kpuy0DrmfP80ajEcaYUrrZNUAoOV7y8UsZjQPhqzBUSlGCEVOYSYIR59zn3K+tfM55HMdhqJVStVhKsZgfJXGkgiAMQ631RlwrgRNCfAZYTTyVc0YDpaaTST4ZJ0lCCDHqeuH7bBrXDMI5A6GpjDgDpQIRjWn8ItKK0r/7/C8YUM7gNlAJ6NzndVJbiDEGxoELTBmmAIwLITcnWznD6DlzLBj2YNhjQwucg63FGPXKt3uXX6zLj9blJ/PV5+76A6FACP6vmFI0evz664P3P8zrG/P6Zufd90dvvmHgBOOt0q6fMEYIYYTqgNx9np+u9d7NwWrK/wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7a82cf10f871f5528718143756fa3a7/e4706/Android_Wizard_Installation_PoE_d01.avif 230w", "/en/static/a7a82cf10f871f5528718143756fa3a7/d1af7/Android_Wizard_Installation_PoE_d01.avif 460w", "/en/static/a7a82cf10f871f5528718143756fa3a7/7f308/Android_Wizard_Installation_PoE_d01.avif 920w", "/en/static/a7a82cf10f871f5528718143756fa3a7/e1c99/Android_Wizard_Installation_PoE_d01.avif 1380w", "/en/static/a7a82cf10f871f5528718143756fa3a7/6e1e4/Android_Wizard_Installation_PoE_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7a82cf10f871f5528718143756fa3a7/a0b58/Android_Wizard_Installation_PoE_d01.webp 230w", "/en/static/a7a82cf10f871f5528718143756fa3a7/bc10c/Android_Wizard_Installation_PoE_d01.webp 460w", "/en/static/a7a82cf10f871f5528718143756fa3a7/966d8/Android_Wizard_Installation_PoE_d01.webp 920w", "/en/static/a7a82cf10f871f5528718143756fa3a7/445df/Android_Wizard_Installation_PoE_d01.webp 1380w", "/en/static/a7a82cf10f871f5528718143756fa3a7/87a8c/Android_Wizard_Installation_PoE_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7a82cf10f871f5528718143756fa3a7/81c8e/Android_Wizard_Installation_PoE_d01.png 230w", "/en/static/a7a82cf10f871f5528718143756fa3a7/08a84/Android_Wizard_Installation_PoE_d01.png 460w", "/en/static/a7a82cf10f871f5528718143756fa3a7/c0255/Android_Wizard_Installation_PoE_d01.png 920w", "/en/static/a7a82cf10f871f5528718143756fa3a7/b1001/Android_Wizard_Installation_PoE_d01.png 1380w", "/en/static/a7a82cf10f871f5528718143756fa3a7/81315/Android_Wizard_Installation_PoE_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7a82cf10f871f5528718143756fa3a7/c0255/Android_Wizard_Installation_PoE_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Installation Wizard`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can find the `}<strong parentName="li">{`QR Code`}</strong>{` in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
        }}>{`WebUI`}</a>{` (A), printed on the label of your camera (B) or on the package.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/072b491e310dea682306d6f2d746f5aa/81315/Android_Wizard_Installation_PoE_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwklEQVQ4y4WUy08aURjFZ+vSncaImrjRxPcjgg/UGiOoKxNaU2AYK8gjgdIWUnQB1II6ggyiPAR01aT9D+zW/jdNuzD9A05zrhmiqdDFyb0ZuL855/u+O1Jvby+6u7sxOjqKqakp9PT0oKOjA52dnQ0ZDAb09/djeHgY7e3taGtra6irqwt9fX3if2RJFqsFFosFW1tbsNlsWF9fx9ramnhGra6uwmQyYmBgQIBnZ2dhMpnEYYrPaEhfpcvLS5RKJRRLRbFeXV2hWq2iXq/j5uYatVoVOS2H5eVlTExMYHx8HENDQ+IFg4OD/0jK5/MoFAq4uLgA4RT3xWIR5XJZvKBSKWNzcxMLCwuiLCMjIxgbG3tWkg7TV10E0nGlUhF7loJR6ZBA1vw5Sap6gkzmFIXCuThcq9VEZIp7PqNrAufn5xvRmwKTyQSOjtLIZFScnz9AKUalCKVDq9UqHBqNxqYwAdzf3wehD+CUgD6uKaGMvrGxAbPZ3KhhU2AkGkE8EcfhYRKp1Gdomvakno9rSHdLS0sicjOolEjEoaoqTk9VsJ4E6k2idCAdzs3NCYctI2t5DXc/7nD7/RYcobOz7BMgR0d3SODMzExrIDt8/+cev37/RC53hk+HSeGWex3IWrIpBDJyq9GRgsEgrm/q+PrtCxKJBPQmsZ6qetwYdt0hD7VsimfPg3A4jGg0ikjkA6LRCA4ODhCPx5FOp6FpOWSzWXGvOTYUb0RT4M6OgnD4LWKxGEKhELw+L/x+n3hJKpXCyckx2Dh+MAjjcDNyU6AsO+Hx7OLd+zAUxQWn0w6Hww6XSxauY7GPCIWCWFl5IcaGcVtGdjgdcCkuuD278Pl8CAQC8Pv9CAT88Hr34Ha7xe+8covmxf83Zfv1NmRFhvJGEQeVHQWy4oRDtsMpO+Bw2vHylU24m5ycxPT0dMsa/gWO69nrBF758QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/072b491e310dea682306d6f2d746f5aa/e4706/Android_Wizard_Installation_PoE_d02.avif 230w", "/en/static/072b491e310dea682306d6f2d746f5aa/d1af7/Android_Wizard_Installation_PoE_d02.avif 460w", "/en/static/072b491e310dea682306d6f2d746f5aa/7f308/Android_Wizard_Installation_PoE_d02.avif 920w", "/en/static/072b491e310dea682306d6f2d746f5aa/e1c99/Android_Wizard_Installation_PoE_d02.avif 1380w", "/en/static/072b491e310dea682306d6f2d746f5aa/6e1e4/Android_Wizard_Installation_PoE_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/072b491e310dea682306d6f2d746f5aa/a0b58/Android_Wizard_Installation_PoE_d02.webp 230w", "/en/static/072b491e310dea682306d6f2d746f5aa/bc10c/Android_Wizard_Installation_PoE_d02.webp 460w", "/en/static/072b491e310dea682306d6f2d746f5aa/966d8/Android_Wizard_Installation_PoE_d02.webp 920w", "/en/static/072b491e310dea682306d6f2d746f5aa/445df/Android_Wizard_Installation_PoE_d02.webp 1380w", "/en/static/072b491e310dea682306d6f2d746f5aa/87a8c/Android_Wizard_Installation_PoE_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/072b491e310dea682306d6f2d746f5aa/81c8e/Android_Wizard_Installation_PoE_d02.png 230w", "/en/static/072b491e310dea682306d6f2d746f5aa/08a84/Android_Wizard_Installation_PoE_d02.png 460w", "/en/static/072b491e310dea682306d6f2d746f5aa/c0255/Android_Wizard_Installation_PoE_d02.png 920w", "/en/static/072b491e310dea682306d6f2d746f5aa/b1001/Android_Wizard_Installation_PoE_d02.png 1380w", "/en/static/072b491e310dea682306d6f2d746f5aa/81315/Android_Wizard_Installation_PoE_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/072b491e310dea682306d6f2d746f5aa/c0255/Android_Wizard_Installation_PoE_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Select your camera model, e.g. `}<strong parentName="li">{`IN-8003FullHD`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/898f1314ec25ee18823d9005e7593c4f/81315/Android_Wizard_Installation_PoE_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXUlEQVQ4y42Ty07bQBSG/QAJCBZAuNhOiTFJ7bmcMze7TpqShECiXEQEWVFV3aOI96m6bN8CiU2r9qkqexK3ElLFp9nM0fznpn+cMAyDINjb26tUKtVqdXt723Xdk5Pj2tHhwXE9DM+DINjf39/a2qpWq5VKxfM813VrtZr7JnTCMGw0Gr1ejzHmum69Xvc8z/dcvxEe8cvgLAyCRqfTUUr5vn96eurneL5fP8SxE29AxCRJtNaIGMVRHorWEELKW/yXyOEFjDGtdbvdzrJMa81fh8M22PRxHBNC2D9QStkLbDAXc84BQBRgAQDYF7YC3WCDlFL7IG9bay2lVErZFFJKIYQxhnOOiN1u9/r6ejQaXRUMh8PRaHTR62ljcrExBhFtCiGEUspeAcBWS5IkLTDGpGmaZRkCZ5TkYqVUWVxKCQCIqJTijHKhsXNV5Mw7MsZorRklkXgXqff5zIgopbTTWrGdilKqlFosFre3d8vlcj6fj8fjyWQqBbb04DybO5RSrbWtmaZpWV8IYRdmtNJKJsUbQkgxyHr/eWUAsJ33+31EFEIAAOdQrBtbotPEdlN04lxJioyMoiJoHLt9uyc7kpTS2oZSKqUaT2+mi+Xk5m4ymc5m09ls9qHbbamLVjpem4QyDiiEVCgk40DZRizEZb836PeGg/7lYDAsSJIk7xtwLYa4CW8D2jqjrTOIAojPSyfFLyg9Z8X06OPXndVv7/HZfXzeWf06+Pyd0bVJX1q6NOlGfP9l9+HnyerpePW0+/Cj9ulbKf4P618FpIlxyKP8YBwiab7mV/0BOz3Zja8pMYgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/898f1314ec25ee18823d9005e7593c4f/e4706/Android_Wizard_Installation_PoE_d03.avif 230w", "/en/static/898f1314ec25ee18823d9005e7593c4f/d1af7/Android_Wizard_Installation_PoE_d03.avif 460w", "/en/static/898f1314ec25ee18823d9005e7593c4f/7f308/Android_Wizard_Installation_PoE_d03.avif 920w", "/en/static/898f1314ec25ee18823d9005e7593c4f/e1c99/Android_Wizard_Installation_PoE_d03.avif 1380w", "/en/static/898f1314ec25ee18823d9005e7593c4f/6e1e4/Android_Wizard_Installation_PoE_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/898f1314ec25ee18823d9005e7593c4f/a0b58/Android_Wizard_Installation_PoE_d03.webp 230w", "/en/static/898f1314ec25ee18823d9005e7593c4f/bc10c/Android_Wizard_Installation_PoE_d03.webp 460w", "/en/static/898f1314ec25ee18823d9005e7593c4f/966d8/Android_Wizard_Installation_PoE_d03.webp 920w", "/en/static/898f1314ec25ee18823d9005e7593c4f/445df/Android_Wizard_Installation_PoE_d03.webp 1380w", "/en/static/898f1314ec25ee18823d9005e7593c4f/87a8c/Android_Wizard_Installation_PoE_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/898f1314ec25ee18823d9005e7593c4f/81c8e/Android_Wizard_Installation_PoE_d03.png 230w", "/en/static/898f1314ec25ee18823d9005e7593c4f/08a84/Android_Wizard_Installation_PoE_d03.png 460w", "/en/static/898f1314ec25ee18823d9005e7593c4f/c0255/Android_Wizard_Installation_PoE_d03.png 920w", "/en/static/898f1314ec25ee18823d9005e7593c4f/b1001/Android_Wizard_Installation_PoE_d03.png 1380w", "/en/static/898f1314ec25ee18823d9005e7593c4f/81315/Android_Wizard_Installation_PoE_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/898f1314ec25ee18823d9005e7593c4f/c0255/Android_Wizard_Installation_PoE_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Select if you are using PoE or the external power supply to power your camera.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0417089cfb4263c84485af00bf22011a/81315/Android_Wizard_Installation_PoE_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACxUlEQVQ4y42RT2/bNhiHdRyyFAMKJECNyLWt2JYlSy//U6RF0ZKVWPYCrEaKOJuz9VC0wNBh2GWHfYMCwdDPs+u+xW77EMtx62CqWXPZsAcveCD5kO+P9MIwHA6Hx8fHBwcHnzoGg4Hv+51O56TbH41GYRgeHR0dHh62q71er9vtdjqdp4NTbzweB0FgrcUY+77f7/e73a7vn3T7wRPcjMNJEAzyPJdS9hxu1X/a6z9hF14URUmSCCEYY+QexhgARPE0dgghKKWEEOyglMZxHE0TjxAihCiKwhhTFIW1th2VUhhjzjljzBhTVdVisZjP59baqqoE54JRr6qq3W633W5vbm42m83V1dX19fXl5eVut6vrWkq5XC5Xq5VSKkkSAEgB0jQBzBJuPSll41gul2dnZ+1Y1/VqtcqyTGtd1/X5+bkxhjEmPsCFzLgyHkIIPyBNU7gHIaS1rhx1XSulOOdCSCmEkJlQuYcxzh1KKa01xhjdgzGezWZN06zX68Vi0Z74oW3CEmH3stZaKdU+eCv/cwRjjDNq8rwwxtrC5Pnc2rKcC045o/u2syyr61pr3f4QQih1IIQAUEqEVNoUpbGlns2M+xegEhD23A5wYQTnPE1Txthms/ni2TPOOQBKsnJcXA6rF6fl18H85rT6ZmS3U3UGVOxl7siyjBDS9qyUKooCXHCShEff/vLJ2/cnt38E7+4evf3z0U+/I0wRJB4ASCmLwmZKp4AAoRQAYSKldBEwAgjLr4L1D+PP30QX3w3X3w/PX+1lBJ5LCFrQmcAZg7Y0J5TSNv/+9mRCpkMcj3A8ItMRmY7b+b2MACb2edi8jpuXcfNy0rwOqx2gh2CEyYPCH2UMyeM3v352+773893w3d3j278Of/wNEYYg3Wv/jrsZQTS7CMsvo/lVVG7D8npiNv+tPZQRTmOcTD5WGqH/wd9etOZ66IELHwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0417089cfb4263c84485af00bf22011a/e4706/Android_Wizard_Installation_PoE_d04.avif 230w", "/en/static/0417089cfb4263c84485af00bf22011a/d1af7/Android_Wizard_Installation_PoE_d04.avif 460w", "/en/static/0417089cfb4263c84485af00bf22011a/7f308/Android_Wizard_Installation_PoE_d04.avif 920w", "/en/static/0417089cfb4263c84485af00bf22011a/e1c99/Android_Wizard_Installation_PoE_d04.avif 1380w", "/en/static/0417089cfb4263c84485af00bf22011a/6e1e4/Android_Wizard_Installation_PoE_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0417089cfb4263c84485af00bf22011a/a0b58/Android_Wizard_Installation_PoE_d04.webp 230w", "/en/static/0417089cfb4263c84485af00bf22011a/bc10c/Android_Wizard_Installation_PoE_d04.webp 460w", "/en/static/0417089cfb4263c84485af00bf22011a/966d8/Android_Wizard_Installation_PoE_d04.webp 920w", "/en/static/0417089cfb4263c84485af00bf22011a/445df/Android_Wizard_Installation_PoE_d04.webp 1380w", "/en/static/0417089cfb4263c84485af00bf22011a/87a8c/Android_Wizard_Installation_PoE_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0417089cfb4263c84485af00bf22011a/81c8e/Android_Wizard_Installation_PoE_d04.png 230w", "/en/static/0417089cfb4263c84485af00bf22011a/08a84/Android_Wizard_Installation_PoE_d04.png 460w", "/en/static/0417089cfb4263c84485af00bf22011a/c0255/Android_Wizard_Installation_PoE_d04.png 920w", "/en/static/0417089cfb4263c84485af00bf22011a/b1001/Android_Wizard_Installation_PoE_d04.png 1380w", "/en/static/0417089cfb4263c84485af00bf22011a/81315/Android_Wizard_Installation_PoE_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0417089cfb4263c84485af00bf22011a/c0255/Android_Wizard_Installation_PoE_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Select your camera from the list. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/22507d64755cca27b024ba3c6b113c16/81315/Android_Wizard_Installation_PoE_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6ElEQVQ4y41SO08UURSeygKBbIICu+z7NezMzsydOzP7mGXZYV8kwC4k2xATwVAYH4WFUTEkJpa2FhaaUGhra2Khf4KaQisT/8Rnzhl2ASNq8eXce8/r+849SqlUQqFQQDQaxezsLGZmZhCJRJBKpZBKJbEUTyCayKKoqsjn81hYXOQYwvT0NMclEgnML8wjmVWhFItF5HI5dDodWJaFZDKJTCbDNp1KIp1TEbP7KKjLyOdzWFlZged5l+KSyQRS6TRizgYUTdNALHVd50ApJRzHgeu6IB9BH1td53eKEULAtm22dDcMg+sotVoNjUYDvu9jbW2NQWzb7Ta/ka9arXIzOpO/2+0yer0ex5FtNpugWgolra6u8kOr1cLOzg4jCAJ+pwIUQ9ja2sLm5iaGwyEGgwHHjUYjttvb29xEqVQq3JmsaZosl2TQuVwuM0gq+cOmAeq+DyFsOI7LkNKZyFaIBVEmSfQpY1BRsjSzer3ODDc2NrC+vo5Ouw3LNGBZJiwzBM2TiCkkk6jSnCj5IogtMaPZkKV50QhaQQDpepCOB9f14LguE6KmCh2oGCURq99BLEm+aQmYpTzm997j2tFPLLz4hqWX3zF19AORxycw3AZMQw8l9/t9rk6JF2WfQ/DMbFNHdvgMi/sfkNl/i+ydd4jePkZ89zVMWYWwzHBt6EdpYUniGDRkYt/p9rAaBNyMd6+swtEysEtZiFIWUiPkIKixEGHB8Q5NZI6X1rYhyyU4mspvYZINy5aXIeTEz3tIH0PS63Wf4TcacL0KPGnDHOxCGx1AGDonWX+Y80UwQ5K7tlJD4Lto1ly0/Aqq0kBT6rDuPof66BWEthyy+1dB/kHDwM2Dj5h6corY4Qmihye4/vQUc/c+QywXYKt5LibO9vOvBceH+K03mHvwFYmHnxg37n9BbO84nKkt/1loUnC8GlLLwFHjsNUEw1mOQ5bSV6zR1VDoN/lHpQshvTN7fh77/xe/ACfLSjW81zswAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22507d64755cca27b024ba3c6b113c16/e4706/Android_Wizard_Installation_PoE_d05.avif 230w", "/en/static/22507d64755cca27b024ba3c6b113c16/d1af7/Android_Wizard_Installation_PoE_d05.avif 460w", "/en/static/22507d64755cca27b024ba3c6b113c16/7f308/Android_Wizard_Installation_PoE_d05.avif 920w", "/en/static/22507d64755cca27b024ba3c6b113c16/e1c99/Android_Wizard_Installation_PoE_d05.avif 1380w", "/en/static/22507d64755cca27b024ba3c6b113c16/6e1e4/Android_Wizard_Installation_PoE_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/22507d64755cca27b024ba3c6b113c16/a0b58/Android_Wizard_Installation_PoE_d05.webp 230w", "/en/static/22507d64755cca27b024ba3c6b113c16/bc10c/Android_Wizard_Installation_PoE_d05.webp 460w", "/en/static/22507d64755cca27b024ba3c6b113c16/966d8/Android_Wizard_Installation_PoE_d05.webp 920w", "/en/static/22507d64755cca27b024ba3c6b113c16/445df/Android_Wizard_Installation_PoE_d05.webp 1380w", "/en/static/22507d64755cca27b024ba3c6b113c16/87a8c/Android_Wizard_Installation_PoE_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/22507d64755cca27b024ba3c6b113c16/81c8e/Android_Wizard_Installation_PoE_d05.png 230w", "/en/static/22507d64755cca27b024ba3c6b113c16/08a84/Android_Wizard_Installation_PoE_d05.png 460w", "/en/static/22507d64755cca27b024ba3c6b113c16/c0255/Android_Wizard_Installation_PoE_d05.png 920w", "/en/static/22507d64755cca27b024ba3c6b113c16/b1001/Android_Wizard_Installation_PoE_d05.png 1380w", "/en/static/22507d64755cca27b024ba3c6b113c16/81315/Android_Wizard_Installation_PoE_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/22507d64755cca27b024ba3c6b113c16/c0255/Android_Wizard_Installation_PoE_d05.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Please choose a name for your camera.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/8efc2/Android_Wizard_Installation_PoE_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD3ElEQVRIx+2U227bRhCG5TqN69o3ASrAkiXLog6URHJPPIkUxeNKJHWwJQGm4NpWkd6mQF+gl0kAtzXaBwjc9iJ9jqKPFcRQQdJWZCcNUPSqQD8MiZnl/NydnSUzu7u70+lUVdWdnZ1yuby1tbWxsZFZY3Nzs1gsNpvNWq12cHCQzWYLhQJCqFQqZYrF4mFCsVjMZrNPEvbuUygUDg8P9/f38/l8Lr5yhXK9UGIy9XrdNE2GYViWnUwmURQNh8NqtVq5T7lcZhhmFebos4LxZYZhGAhh+sw0TUppr9djGObwPuWEVbAvHxWgk2k2m5VKpZZQKpUOEmq1Gsuy9QQ2oV6vrw/Wq5Vmg81gjH3fD4KAUmrbtuM4YRgGQWDbNqXUcZxer0cThsNhp9OhlPq+73kuISQW67ru+34YhrZte57X6/Vc1+33+2EYDodD13VN0/Q8bzWBZVmUUoxxBiEEIRQSAACpw3EcpXQwGIzH4zAMCSE8z8cJEIIEQRAQQvHMq1gQBAghSUAI4TUQABghJAAMIYYwDjGOxa7rphVSSg3DiFPXiF8nSZpjy5qmuY5iGGrX0BwHE5IhhPi+PxqN5vN5FEVHR0eSJEEI18WiLJvDQdsyzdHA6PcMv2+OBkQU45n5BI7jWq1WWgy+D4IIcBwSAOA4KAiA5wHPY4TimTVNUxRFlmVd11VVlWX5vhSJkqR0OpKqKh1daquKrsvtNk5bNZvNzs/Pz87OoihaLBanp6frK4cAyLpuT48Nv28dj40gvnfDgMhyLCZ3IIQIIfh97hJSdzWcWe0tTEj99+Uo0T0YiWueTqeLxSJKkGV5favfbbiqWuNhNwys8cgajzQ3aRVCSFVVXdc1Tet2u6Ioog+BMRYliUiSKMuiopAk7bZV3B08z39w2XFtACAAoCBAABCEtzXPZrOTk5Pj4+PJdDqJf0ltAMDDZStKNww6PdoNA8Pvty3rtlWO43ie53qe55iepSmYxzxLhMY741kRttqaqOqK2u2qlilpbZR+VckBEwS+1ZCdqhmxVtSw5w17XjPnVXPOJn7DiurmvKUFUOAhAOmmZm5XRiSRY/Jf/fbpD8vt528+e3Hz+cubvau3e1c3Oy9vtl7cbD9/8/j75Rff/IGFJsIkFd2KIRbFVnnv698/+Xn5+PLto8vl9o9L8dcl/mW5c7V8dBkPbv60fPLtn4kY3xMjTIjQqAbPck9f5y9e5RfXuYvr7FlsuYvr/OI6f/Eq9/R1efIdBjxCD8RpL4Um4SqEq6YmchVxLYwf8ex6FzIPjkJcz8cM/734H/K/+L8jJv+CvwAb9b/778LpmQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/e4706/Android_Wizard_Installation_PoE_d06.avif 230w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/d1af7/Android_Wizard_Installation_PoE_d06.avif 460w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/ec170/Android_Wizard_Installation_PoE_d06.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/a0b58/Android_Wizard_Installation_PoE_d06.webp 230w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/bc10c/Android_Wizard_Installation_PoE_d06.webp 460w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/712de/Android_Wizard_Installation_PoE_d06.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/81c8e/Android_Wizard_Installation_PoE_d06.png 230w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/08a84/Android_Wizard_Installation_PoE_d06.png 460w", "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/8efc2/Android_Wizard_Installation_PoE_d06.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f8fb42c69a1ca7cf0d7ed212bf0b1e83/8efc2/Android_Wizard_Installation_PoE_d06.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      